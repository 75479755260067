<template>
  <h1>Personvernerklæring</h1>
  <div>
    <h2>1. Behandlingsansvarlig</h2>
    <p>
      Elin Holde Hegsvold er på vegne av Finstadbru Hundesport
      behandlingsansvarlig for selskapets behandling av personopplysninger.
    </p>
  </div>
  <div>
    <h2>2. Personopplysninger som lagres</h2>
    <p>
      Ved booking av Finstadbru Hundesport sitt treningsområde lagres følgende
      personopplysninger: navn, e-postadresse og telefonnummer.
    </p>
  </div>
  <div>
    <h2>3. Formål med behandlingen</h2>
    <p>
      Vi lagrer opplysningene for å kunne gjennomføre våre forpliktelser etter
      avtale med deg. Det vil si at vi må vite hvem du er og hvordan vi kan
      kontakte deg, for å kunne informere deg om forhold knyttet til din
      booking.
    </p>
  </div>
  <div>
    <h2>4. Innhenting av personopplysninger</h2>
    <p>
      Vi lagrer de personopplysningene du har avgitt i vår database. Vi bruker
      IKKE informasjonskapsler/cookies på vår nettside.
    </p>
  </div>
  <div>
    <h2>5. Utlevering av opplysninger til tredjeparter</h2>
    <p>
      Vi vil ikke dele, selge, overføre eller på annen måte utlevere
      personopplysninger til andre, med mindre vi er rettslig forpliktet til
      det.
    </p>
  </div>
  <div>
    <h2>6. Sletting av personopplysninger</h2>
    <p>
      Opplysninger vi mottar i forbindelse med din booking vil lagres på
      ubestemt tid.
    </p>
  </div>
  <div>
    <h2>7. Rettigheter for den registrerte</h2>
    <p>
      Vi behandler dine personopplysninger i henhold til personopplysningsloven
      og gjeldende forskrifter. Det gjøres oppmerksom på at du kan kreve innsyn
      i og sletting av egne personopplysninger. Det kan klages til Datatilsynet
      på behandling i strid med reglene.
    </p>
  </div>
  <div>
    <h2>8. Personvernombud</h2>
    <p>
      Vi har et personvernombud, Elin Holde Hegsvold, som påser at
      personopplysningslovens regler om behandling av personopplysninger blir
      fulgt.
    </p>
  </div>
  <div>
    <h2>9. Informasjonssikkerhet</h2>
    <p>
      Vi sikrer dine personopplysninger ved både fysisk og virtuell adgangs- og
      tilgangskontroll.
    </p>
  </div>
  <div>
    <h2>10. Kontaktinformasjon</h2>
    <p>
      Henvendelser om hvilke opplysninger som er registrert og evt. sletting av
      disse kan sendes til følgende epost-adresse: post@finstadbru.no
    </p>
  </div>
  <div class="spacer"></div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Privacy Policy",
});
</script>
<style scoped>
h1 {
  margin-bottom: 5rem;
}
h2 {
  font-size: 1.5rem;
}
p {
  width: 40rem;
  margin: 0 auto;
  margin-bottom: 3rem;
}
.spacer {
  margin-bottom: 10rem;
}
@media screen and (max-width: 768px) {
  p {
    width: 20rem;
  }
}
</style>
